@import '../../../../node_modules/bootstrap/scss/functions';

// Default variables
$primary-color: #e5be61;
$secondary-color: #c02a2a;
$text-gray: #333;
$link-decoration: none;
$enable-shadows: true;
$prefix: "bs-";
$link-color: #333;
// Input
$input-bg: #f7f7f7;
// Border
$divider-light: 1px solid #343a40;
$divider: 1px solid #eeeeee;
// Navbar 
$navbar-nav-link-padding-x: 1rem;
// Color
$gradient-yellow: linear-gradient(to bottom,#F6ce6d,#c6a34d);
$gradient-red: linear-gradient(to bottom,#cf2e2e,#ab2626);
// Enable negative margin and padding
$enable-negative-margins: true;

// Bootstrap required
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/maps";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/root";

// Bootstrap components
@import "../../../../node_modules/bootstrap/scss/grid";
@import "../../../../node_modules/bootstrap/scss/buttons";
@import "../../../../node_modules/bootstrap/scss/card";

// Bootstrap utilities must include with api
@import "../../../../node_modules/bootstrap/scss/utilities";
@import "../../../../node_modules/bootstrap/scss/utilities/api";
@import "../../../../node_modules/bootstrap/scss/reboot";
@import "../../../../node_modules/bootstrap/scss/containers";
@import "../../../../node_modules/bootstrap/scss/badge";
@import "../../../../node_modules/bootstrap/scss/tables";
@import "../../../../node_modules/bootstrap/scss/progress";
@import "../../../../node_modules/bootstrap/scss/dropdown";
@import "../../../../node_modules/bootstrap/scss/forms";
@import "../../../../node_modules/bootstrap/scss/nav";
@import "../../../../node_modules/bootstrap/scss/navbar";
@import "../../../../node_modules/bootstrap/scss/offcanvas";
@import "../../../../node_modules/bootstrap/scss/transitions";
@import "../../../../node_modules/bootstrap/scss/carousel";
@import "../../../../node_modules/bootstrap/scss/list-group";
@import "../../../../node_modules/bootstrap/scss/modal";
@import "../../../../node_modules/bootstrap/scss/close";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

* {
    color: #333;
    font-family: uigg;
}
img {
    max-width: 100%;
    height: auto;
}
ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}
.header {
    height: 7rem;
    border-bottom: $divider;

    & .form-control {
        border: none;
    }
}
.form {
    &-control, &-select {
        border-color: #dee2e6;
        background-color: transparent;
        box-shadow: none !important;
        height: 3rem;
        line-height: 2.2rem;
        border-radius: 2rem;
    }

    &-check-input {
        border-width: 2px;
        border-radius: 0 !important; 
        border-color: $primary-color;
        background-color: transparent;
        &:checked {
            background-color: $primary-color;
            border-color: $primary-color;
        }
        &:focus {
            border-color: $primary-color;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(229 190 97 / 25%);
        }
    }
    &-register {
        width: 60%;
        & .bg-primary {
            background: $gradient-red;
            color: #fff;
        }
        & .btn-reg {
            margin-top: 1rem;
        }
    }
}
.member-info .form {
    &-control, &-select {
        width: 50%;
    }
}
.member-menu {
    & .active {
        border-bottom: 1px solid #000;
    }
}
.input-style {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $input-bg;
    border-radius: 100px;
    padding: 0 .5rem;
    height: 2.8rem;
}
.btn {
    position: relative;
    background-color: $primary-color;
    color: #fff;
    text-align: center;
    padding: .77em .9rem;
    border-radius: .5rem;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    border: none;
    transition: all .3s;

    &:hover {color: #fff !important;transform: translateY(.5rem);}

    &-gold-gradient {
        background-image: $gradient-yellow;
        border-radius: 100px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        &.btn-shadow::after { 
            box-shadow: 0 5px 10px $primary-color;
        }
    }
    &-red-gradient {
        background-image: $gradient-red;
        border-radius: 100px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;

        &.btn-shadow::after {
            box-shadow: 0 5px 10px $secondary-color;
        }
    }
    &-gold-nobg {
        background-color: transparent;
        border: 2px solid $primary-color !important;
        color: $primary-color;
        border-radius: 2rem;
        text-transform: uppercase;
        min-width: 12%;

        &:hover {
            color: $primary-color !important;
        }
    }
}
.btn-shadow::after {
    width: calc(100% - 2rem);
    height: 1rem;
    border-radius: 100px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: .7;
    content: '';
}
.lang {
    & button {
        border: none !important;
        outline: none !important;
        background: transparent;
        box-shadow: none;
    }
    & .btn:hover {
        transform: none;
    }
}
.flag span {
    font-size: 2rem !important;
}
.nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: .92rem;
    font-weight: bold;
    color: $text-gray;
    text-transform: uppercase;

    &:hover,
    &.active {background-image: $gradient-yellow;color: #fff;}
}
.carousel img {
    min-height: 16rem;
    object-fit: cover;
}
.carousel .visually-hidden {display: none;}
.notice {
    display: flex;
    padding: 1rem 0;
    border-bottom: $divider;
    & i {
        margin-right: .8rem;
        color: $primary-color;
    }
    a {
        font-size: .88rem;
    }
}
.game {
    &-link a {
        width: 100%;
        height: 5rem;
        border-radius: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s;

        &:hover {
            background-color: $gray-100;
        }
    }
    &-link img {
        max-width: 70%;
        max-height: 70%;
    }
    &-list {
        & a {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 1rem;
            overflow: hidden;
            transition: all .3s;

            &:hover {
                transform: translateY(-.5rem);
            }
        }

        & img {
            width: 100%;
            height: 100%;
        }
    }
    &-category {
        margin-bottom: 3rem;
    }
}
.home-loker {
    width: 100%;
    height: 100%;
    background-image: url(../../../../public/images/kg/home/loker/bg.jpg);
    background-size: 100% 100%;
    border-radius: .5rem;

    & img {
        width: 100%;
    }
    &-all {
        width: 100%;
       & span {
            padding: 0 1.2rem;
            & h5 {
                flex: 0 0 24%;
                text-align: center;
                margin: 0;
            }
            & b {
                flex: 1;
                text-align: center;
                color: $primary-color;
                font-size: 1.2rem;
            }
        }
    }
    & marquee {
        width: 85%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 82%;
        overflow: hidden;
        & li {
            background-color: rgba(255, 255, 255, .1);
            padding: .5rem;
            margin: 1rem 0;
            border-radius: .5rem;
            display: flex;
            align-items: center;

            & img {
                width: 6rem;
                height: 3.8rem;
                padding: .6rem;
            }
            & b {
                flex: 1;
                text-align: center;
                color: $primary-color;
                font-size: 1.2rem;
            }
        }
    }
}
.app {
    margin-bottom: 8rem;
    background-image: url(../../../../public/images/kg/home/phone.jpg);
    background-size: cover;
    background-position: center;

    &-cont {
        padding: 4rem 0 4rem 60%;
        & i {
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 50%;
            margin-right: .6rem;
            background: $secondary-color;
        }
        & p {
            margin: 2.5rem 0 4.5rem 0;
        }
        & li {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 1rem;
        }
        & a {
            width: 100%;
        }
        & img {
            width: 9rem;
            height: 9rem;
            margin-bottom: 1.2rem;
        }
    }
}

.promo {
    margin-bottom: 8rem;
    & cite {position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
    & u {
        position: absolute;opacity: .9;
        &:nth-child(1) {left: 50px;top: -80px;}
        &:nth-child(2) {right: -50px;bottom: 80px;}
        &:nth-child(3) {right: 100px;top: -70px;}
        &:nth-child(4) {left: 40%;top: -60px;}
        &:nth-child(5) {right: 150px;bottom: -80px;}
        &:nth-child(6) {left: 50px;bottom: -80px;}
    }
    &-list {
        background-color: $gray-200;overflow: hidden;
        & h5 {text-transform: capitalize;flex: 1;}
        & span {
            display: flex;align-items: center;
            & i {
                width: 5.2rem;
                height: 5.2rem;
                line-height: 5.4rem;
                border: 2px $primary-color solid;
                border-radius: 100%;
                color: $primary-color;
                font-size: 2rem;
                margin: 0 1.5rem;
                text-align: center;
            }
        }
        & .row span {
            flex: 0 0 50%;
        }
    }
}
.footer {
    background-image: url(../../../../public/images/kg/footer-bg.jpg);
    background-size: cover;

    &-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.2rem 0;
        border-bottom: $divider-light;
        & a {
            color: $primary-color;
            text-transform: uppercase;
            display: block;
            padding: 0 1rem;
            border-radius: 2.2rem;
            line-height: 2.6rem;

            &:hover {
                background-image: $gradient-yellow;
                color: #ffffd9;
            }
        }
    }
    &-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-list {
        display: flex;
        justify-content: space-between;
        & li {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        & h6 {
            color: #fff;
            font-size: 1rem;
            text-transform: uppercase;
            margin-bottom: .6rem;
            padding: 5px 10px;
        }

        & a {
            color: #666;
            font-size: .9rem;
            padding: 5px 10px;
            text-transform: capitalize;
            border-radius: 5px;

            &:hover {
                background: #333;
                color: #fff;
            }
        }
    }
    &-contact {
        & h3 {
            color: #fff;
            font-size: 1.4rem;
            text-transform: uppercase;
        }
        & ul {
            display: flex;
        }
        & li i {
            font-size: 1.6rem;
            color: $primary-color;
            margin-right: .5rem;
        }
    }

    &-copy {
        border-top: $divider-light;
        color: #ffffd9;
        padding: 1rem 0;
        text-align: center;
        text-transform: uppercase;
    }
}
.game {
    &-cont {
        display: block;
        border-radius: 1.2rem;
        margin-bottom: 1.2rem;
        overflow: hidden;

        & h5 {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            margin: 0;
            background-image: url(../../../../public/images/kg/game/list.svg);
            background-size: cover;
            height: 4rem;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            transform: translateY(.5rem);
            line-height: 4.4rem;
            transition: all .3s;
        }

        &:hover {
            & > h5 {
                transform: translateY(0);
            }
            & > .game-enter {
                opacity: 1;
            }
            & > .game-logo {
                left: 2rem;
            }
        }
    }
    &-bg {
        width: 100%;
    }
    &-logo {
        position: absolute;
        z-index: 2;
        width: 9.4rem;
        top: 1.5rem;
        left: 1.8rem;
        transition: all .3s;

        &-poker {
            left: .8rem !important;
        }
    }

    &-enter {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        background-image: $gradient-yellow;
        color: #fff;
        text-align: center;
        width: 5.4rem;
        height: 3.2rem;
        line-height: 3.2rem;
        border-radius: 3.4rem;
        text-transform: uppercase;
        z-index: 3;
        transform: translate(-50%, -50%);
        transition: all .3s;

        &:hover {
            transform: translate(-50%, -40%);
        }
    }
}
.menu {
    width: 100%;
    &-logo {
        top: -2.8rem;
        left: 0;
        right: 0;
        width: 5.6rem;
        margin: auto;
        z-index: 3;
    }
    &-top {
        margin-bottom: -1.6rem;
        padding: 4rem .6rem .6rem .6rem;
        background: $gradient-red;
        z-index: 2;

        &-item {
            width: 100%;
            padding: .8rem .5rem;
            background: rgba(0,0,0,.2);
        }
    }
    &-bottom {
        background: #333;

        & a {
            border: 0;
            background: transparent;
            text-decoration: none;

            &:hover {
                background-image: $gradient-yellow;
                border-radius: 2rem;
            }
        }
    }
    & span.menu-primary-color {
        color: $primary-color !important;
    }
    & span, & a {
        text-transform: capitalize;
        font-size: .96rem;
        color: #fff !important;
    }
}
.member-info {

    &-table {
        & .btn-ref {
            height: 2.4rem;
        }
        & td {
            padding: .8rem 0;
            vertical-align: middle;
        }
        & td:first-child {
            width: 26%;
            text-transform: capitalize;
            font-weight: bold;
        }
    }
}
.thead-bg {
    background-color: $primary-color;
    & th {
        color: #fff;
    }
}
.turnover {
    &-item {
        width: 25%;
    }
}
@media(max-width:1200px) {
    .form {
        &-control, &-select {
            width: 75%;
        }
    }
    .btn {font-size: .9rem;}
    .app {
        background-image: url(../../../../public/images/kg/home/phone-mob.jpg);
        &-cont {
            padding: 1rem 1rem 32rem;
        }
    }
}
@media(max-width:640px) {
    .form {
        &-control, &-select {
            width: 100%;
        }
    }
    .turnover {
        &-item {
            width: 50%;
        }
    }
    .promo {
        & u {
            width: 4rem;
            height: 4rem;
            &:nth-child(1) {left: 1.5rem;top: -2.5rem;}
            &:nth-child(2) {right: -2.5rem;bottom: 1.5rem;}
            &:nth-child(3) {right: 2rem;top: -2.5rem;}
            &:nth-child(4) {top: -2.5rem;}
            &:nth-child(5) {right: 4rem;bottom: -2.5rem;}
            &:nth-child(6) {left: 1.5rem;bottom: -2.5rem;}
        }
        &-list {
            & span {
                & i {
                    width: 3.2rem;
                    height: 3.2rem;
                    line-height: 3.2rem;
                    font-size: 1.8rem;
                    margin: .5rem;
                }
            }
            & h5 {
                font-size: .9rem;
            }
        }
    }
}

