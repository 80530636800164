body {
  background-color: #1b191a;
  color: #fff;
}
.header-top {
  background-color: #0d0c0d;
}
.header-bottom {
  border-bottom: 2px solid #d8c46a;
}
.form-register {
  width: 34rem;
  background-color: #fff;
  overflow: hidden;
}
.text-primary {
  color: #d8c46a !important;
}
.bg-primary {
  background-color: #d8c46a !important;
  color: #fff;
}
.title-register {
  font-size: 1.6rem;
}
.btn-reg {
  height: 2.8rem;
}
.carousel-item {
  height: 26rem;
}

.carousel-item img {
  height: 26rem;
}

.box {
  width: 100%;
}
.box-item {
  width: 100%;
  cursor: pointer;
  border: 1px solid rgb(54, 54, 54);
  margin: 70px 0px 0px 8px;
  background: rgb(12, 12, 12);
}
.box-item-top {
  width: 100%;
  position: relative;
}
.box-item-bottom {
  width: 100%;
  margin: 5px 0;
}
.box-bg {
  width: 100%;
  height: 12rem;
  border: 2px solid transparent;
}
.box-logo {
  position: absolute;
  left: 1rem;
  top: 1rem;
}
.box-logo-img {
    position: absolute;
    right: 0;
    bottom: 2px;
}
.box-item-bottom a {
  width: 8rem;
  padding: .1rem .4rem;
    background: #ffce4b;
    color: #272727;
    border-radius: 1rem;
    text-transform: uppercase;
}

.box .box-item:hover {
  background: #fff;
}
.box .box-item:hover .box-item-bottom span {
  color: #171717 !important;
}
.box .box-item:hover .box-item-bottom a {
  background: #6e531f;
  color: #f3f3f3;
}
.promo-box {
  margin: 0 auto;
}
.promo-list-item {
  margin-bottom: 2rem;
  width: 20rem;
}
.promo-list-item:not(:nth-child(3n)) {
  margin-right: 2rem;
}
.promo-list-item img {
  border-bottom: 2px solid #ffce4b;
}
.promo-list-item .card-title {
  height: 3rem;
}
.info-table td:first-child {
  text-align: right;
}
.transfer-table td:first-child {
  width: 30%;
}
.turnover-item {
  width: 33.33%;
}
.left-content {
  width: 20%;
}
.right-content {
  width: 80%;
}
.form-register label {color: #000;}
.lottery {
  background: #000;
}
.lottery-content {
  background: #fff;
}
.lottery-type {
  background: #333;
}
.lottery-type a {
  width: 11.11%;
  padding: 1rem 0;
  color: #fff;
  text-decoration: none;
  text-align: center;
}
.lottery-type a.active {background: #f90;}
.lottery-info {background: #ccc;border: 1px #666 solid;}
.lottery-info > * {color: #000;}
.lottery-time {font-size: .8rem;}
.lottery-menu {
  display: flex;
  flex-direction: column;
}
.lottery-menu a {
  padding: .6rem 1rem;
  border-radius: 20px 0 0 20px;
  background: linear-gradient(to bottom, #5d5d5d 0, #4f4f4f 100%);
  color: #fff;
  text-decoration: none;
  margin-left: 1rem;
  margin-bottom: .3rem;
  text-transform: uppercase;
}
.lottery-menu a.active {
  background: #fff;
  color: #000;
}
.lottery-col {
  margin-top: .6rem;
}
.lottery-col input, .lottery-label span {
  color: #000;
  height: 2.6rem;
  line-height: 2.6rem;
  text-align: center;
}
.lottery-col input {
  border: 1px #ccc solid;
}
.lottery-col > * {
  display: flex;
  justify-content: center;
}
.lottery-footer {
  height: 3.4rem;
  background: #333;
}
.lottery-submit {
  background: #f90;
  text-decoration: none;
  color: #fff;
  height: 100%;
  line-height: 3.66rem;
}
.lottery-col input[type="text"] {
  width: 100%;
}